/* KANIT FONT */
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* TOMORROW FONT */
@import url("https://fonts.googleapis.com/css2?family=Tomorrow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* INTER FONT */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* POPPINS FONT */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: #009ee8;
    --primary-rgb: 0 158 232;
    --secondary-color: #54a1c0;
    --secondary-rgb: 84 161 192;
    --grey: #e0dede;
    --grey-rgb: 224 222 222;
    --dark: #080919;
    --dark-rgb: 22 22 22;
    --light: #f7f7f7;
    --light-rgb: 247 247 247;
}

* {
    margin: 0;
    padding: 0;
    transition: all 0.1s ease-in-out;
    scroll-behavior: smooth;
}

html {
    overflow-x: hidden;
    min-height: 100vh;
    scroll-behavior: smooth;
}

#root {
    scroll-behavior: smooth;
    min-height: 100vh;
}

body {
    font-family: "poppins", sans-serif;
    position: relative;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    box-sizing: border-box;
    scroll-behavior: smooth;
    width: 100%;
    overflow: hidden;
    font-size: 18px;
    background: var(--light);
}

input,
select,
textarea {
    outline: 0;
}

input:focus,
select:focus,
textarea:focus {
    box-shadow: 0px 0px 2px 2px rgb(0, 220, 240, 0.4),
        0px 0px 2px 4px rgba(93, 165, 168, 0.3),
        0px 0px 2px 4px rgba(255, 255, 255, 0.2);
}

.breaker {
    word-wrap: break-word;
    word-break: break-all;
    hyphenate-limit-chars: 8 2;
    hyphens: auto;
    line-break: strict;
    overflow-wrap: break-word;
    white-space: break-spaces;
    hanging-punctuation: first;
    line-clamp: 3;
    text-overflow: ellipsis;
}

.wrapper {
    width: 98%;
    max-width: 1350px;
    margin: auto;
    height: 100%;
}

.shadow-text {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 5);
}

.text-stroke {
    -webkit-text-stroke: 1px black;
}

.font-bree {
    font-family: "kanit", serif;
}

.font-tomorrow {
    font-family: "tomorrow", serif;
}

.drop-in {
    animation: dropInIntro 2s ease 0s 1 normal forwards;
}

@keyframes dropInIntro {
    0% {
        animation-timing-function: ease-in;
        opacity: 0;
        transform: translateY(-250px);
    }

    38% {
        animation-timing-function: ease-out;
        opacity: 1;
        transform: translateY(0);
    }

    55% {
        animation-timing-function: ease-in;
        transform: translateY(-65px);
    }

    72% {
        animation-timing-function: ease-out;
        transform: translateY(0);
    }

    81% {
        animation-timing-function: ease-in;
        transform: translateY(-28px);
    }

    90% {
        animation-timing-function: ease-out;
        transform: translateY(0);
    }

    95% {
        animation-timing-function: ease-in;
        transform: translateY(-8px);
    }

    100% {
        animation-timing-function: ease-out;
        transform: translateY(0);
    }
}

* {
    scrollbar-width: thin; /* Thin scrollbar */
}

*::-webkit-scrollbar {
    width: 9px;
    background-color: rgba(var(--light-rgb));
    height: 9px;
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(var(--grey-rgb));
}

*::-webkit-scrollbar-thumb:hover {
    background-color: rgba(var(--secondary-rgb) / 0.7);
}

@media only screen and (max-width: 600px) {
    body {
        font-size: 16px;
    }
}

.ellipsisText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    /* max-height: 4em; 
    line-height: 1.5em;  */
}

.ellipsisText::after {
    content: "...";
    font-size: smaller;
    color: #ccc;
}

/* HOME PAGE */

.fade-border-white {
    border: 6px solid;
    border-image: linear-gradient(to right, #fff, #ffffff80) 4;
}

.bg-constellation {
    background: url("./assets/svgs/endless-constellation.svg");
}

.bg-living-room {
    background: url("./assets/images/house-hero-section.png");
    background-size: cover;
}

.bg-bridge-tower {
    background-image: linear-gradient(
            to top right,
            rgba(var(--dark-rgb) / 0.9) 20%,
            rgba(var(--primary-rgb) / 0.1)
        ),
        url("./assets/images/house-bungalow-white.jpg");
}

.bg-tower-night {
    background-image: linear-gradient(
            to top left,
            rgba(var(--dark-rgb) / 0.8) 50%,
            rgba(var(--primary-rgb) / 0.2)
        ),
        url("./assets/images/livingroomdecent.jpg");
}

.mobile-nav-component {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.mobile-nav-component-open {
    clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
    visibility: visible;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 600px) {
}

/* //? ========= SERVICES ========== //? */

.city-large-towers {
    background-image: linear-gradient(
            to top left,
            rgba(var(--dark-rgb) / 0.8) 50%,
            rgba(var(--primary-rgb) / 0.2)
        ),
        url("./assets/images/city-large-towers.jpg");
}

.apartments-story-buildings {
    background-image: linear-gradient(
            to top left,
            rgba(var(--dark-rgb) / 0.8) 50%,
            rgba(var(--primary-rgb) / 0.2)
        ),
        url("./assets/images/apartments-story-buildings.jpg");
}

.list-house {
    list-style: url("./assets/icons/cursor.png");
}

li::marker {
    list-style: url("./assets/icons/cursor.png");
    width: 10px;
    background: #000;
    border: 3px solid red !important;
    text-align: center !important;
}

/* DESIGNING */

.bg-house-design-page {
    background-image: linear-gradient(
            to top right,
            rgba(var(--dark-rgb) / 0.8) 50%,
            rgba(var(--primary-rgb) / 0.2)
        ),
        url("./assets/images/deisgn-page-banner-house.jpg");
}
